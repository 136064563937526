<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-collapse withBackground>
        <span slot="header">{{ $t('layout.filters.label') }}</span>
        <template slot="body">
          <filters
            :loading="loading"
            :actions="filterActions"
            @filter="filterData"
            @clear-filters="filterData(null)"
          />
        </template>
      </va-collapse>
    </div>
    <div class="flex xs12">
      <va-card :title="$t('districts.plans.index')">
        <remote-table
          crud-links="districtPlans"
          controller="DistrictPlans"
          delete-route="district-plans"
          :columns="tableFields"
          :data="tableData"
          :loading="loading"
          :pagination="pagination"
          :search="searchOptions"
          :params="serverParams"
          :queries="serverParams.queries"
          :sortField="sort.field"
          :sortType="sort.type"
          @update-data="updateData"
          @search-data="searchByName"
          @delete-item="tryDelete"
          @download-item="prepareDownloadPlan"
        />
      </va-card>
    </div>
    <div
      v-if="currentUser.can('DistrictPlans', 'download')"
      class="flex xs12"
    >
      <va-card :title="$t('tables.headings.download')">
        <yearly-bar
          selection="district"
          :loading="loading"
          @submit="prepareDownloadPlan"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const YearlyBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Yearly')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'district-plans-index',
  components: {
    RemoteTable,
    Filters,
    YearlyBar,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      tableActions: [],
      totalTeams: 0,
      sort: {
        field: 'year',
        type: 'desc',
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'year',
          type: 'desc',
        },
        queries: '',
        page: 1,
        perPage: 50,
      },
      filterActions: ['regions', 'countries', 'districts', 'yearSince', 'yearUntil'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tableFields () {
      return [
        {
          name: 'year',
          title: this.$t('tables.headings.year'),
          sortField: 'year',
        },
        {
          name: 'district.country.name',
          title: this.$t('tables.headings.country'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'district.name',
          title: this.$t('tables.headings.district'),
          sortField: 'Districts.name',
          callback: this.translatedName,
        },
        {
          name: 'manager_name',
          title: this.$t('tables.headings.district_manager'),
          sortField: 'manager_name',
        },
        {
          name: 'manager_email',
          title: this.$t('tables.headings.email'),
          sortField: 'manager_email',
        },
        {
          name: 'submitter.name',
          title: this.$t('tables.headings.submitter'),
          sortField: 'Submitter.name',
        },
        {
          name: 'created_at',
          title: this.$t('tables.headings.created_at'),
          callback: this.dateLabel,
          sortField: 'created_at',
        },
        {
          name: '__slot:actions',
          hidden: this.tableData.length === 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    dateLabel (date) {
      return this.$date.format(date, 'dd/MM/yyyy')
    },
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (filters.yearSince) {
        queries += '&year_since=' + filters.yearSince
      }
      if (filters.yearUntil) {
        queries += '&year_until=' + filters.yearUntil
      }

      this.serverParams.queries = queries
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.district) {
          d.district = {
            name: '',
          }
        }
        if (!d.district.country) {
          d.district.country = {
            name: '',
          }
        }
        if (!d.submitter) {
          d.submitter = {
            name: '',
          }
        }
      }
      return data
    },
    apiUrl (params) {
      let route = 'district-plans'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'district.country.name':
            field = 'Countries.name'
            break
          case 'district.name':
            field = 'Districts.name'
            break
          case 'submitter.name':
            field = 'Submitter.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }
      if (params.search) {
        route += '&q=' + params.search
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('district-plans?q=' + name)
      } catch (err) {
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('district-plans/' + item.id)
      } catch (err) {
        this.loading = false
        return
      }
      this.updateData()
    },
    async prepareDownloadPlan (filters) {
      const prepareRoute = 'district-plans/download'
      const downloadRoute = 'district-plans/download/'

      return this.downloadFile({ data: filters }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
